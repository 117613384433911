@keyframes fadeInUpwards {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.scroll-fade-in-upwards {
  animation-name: fadeInUpwards;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

@keyframes growUpwards {
  from {
    max-height: 3px;
  }
  to {
    max-height: 100px;
  }
}

.scroll-grow-upwards {
  animation-name: growUpwards;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}