:root {
  /* --fc-button-bg-color: theme('colors.gray.100'); */
}

.fc-toolbar-title {

  font-size: initial;
  @apply !text-xl md:!text-2xl font-medium text-gray-900 dark:text-gray-100;
}

.fc-theme-standard th {
  border-width: 0 1px;
}

.fc .fc-scrollgrid {
  border: none ;
}

.fc-button-group {
  @apply gap-x-2
}

.fc .fc-button {
  @apply py-1 !rounded-lg capitalize text-sm;
}
.fc .fc-prev-button, .fc .fc-next-button {
  @apply px-1.5;
}

.fc .fc-header-toolbar {
  @apply !mb-4 p-2 md:p-4 flex-col md:flex-row gap-2;
}

td[role=presentation] {
  border: 0 !important;
}

.fc-theme-standard th {
  @apply border-gray-50 dark:border-gray-950;

}

.fc-theme-standard td {
  @apply border-gray-100 dark:border-gray-900;
}

.fc .fc-button-primary {
  @apply bg-gray-200 dark:bg-gray-800 !text-gray-900 dark:!text-gray-100 border-none hover:!bg-gray-300 dark:hover:!bg-gray-700;
  color: theme('colors.gray.500');
}

.fc .fc-button-primary:disabled {
    @apply bg-gray-100 dark:bg-gray-900 hover:!bg-gray-200 dark:hover:!bg-gray-800 hover:cursor-not-allowed;
}

.fc .fc-button-active {
  @apply !bg-green-300 dark:!bg-green-700;
}

.fc .fc-timegrid-slot-label-cushion {
  @apply text-sm;
}

.fc-col-header-cell-cushion {
  @apply text-sm;

}

.fc-event {
  @apply hover:brightness-105 cursor-pointer;
}

.fc-scroller::-webkit-scrollbar {
  width: 8px; /* Width of the vertical scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
  }
  
  .fc-scroller::-webkit-scrollbar-track {
    background: rgba(0,0,0,0); 
  }
  
  .fc-scroller::-webkit-scrollbar-thumb {
    height: 25px;
@apply hover:bg-gray-300 hover:opacity-100 duration-150 transition-opacity opacity-50 dark:hover:bg-gray-700 rounded-full bg-gray-200 dark:bg-gray-800;
  }
  
  /* .fc-scroller::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */


  .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
    @apply border-none shadow-none p-px;
  }

  .fc-timegrid-event-harness > .fc-timegrid-event {
    @apply inset-px;
  }

  .fc .fc-timegrid-col.fc-day-today,
  .fc .fc-daygrid-day.fc-day-today {
    @apply bg-green-500/10 dark:bg-green-500/10;
  }

  /* .fc .fc-scrollgrid-liquid {
    height: calc(100% - 68px);
  } */