/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap'); */
/* @import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap"); */
:root {
  --font-family: "Outfit", sans-serif;

  --color-primary: '#1D91E0',
  --color-primary-50: '#E2F1FB',
  --color-primary-100: '#D0E8F9',
  --color-primary-200: '#ACD7F4',
  --color-primary-300: '#88C6F0',
  --color-primary-400: '#64B4EB',
  --color-primary-500: '#40A3E6',
  --color-primary-600: '#1D91E0',
  --color-primary-700: '#1771AE',
  --color-primary-800: '#10517D',
  --color-primary-900: '#0A314B',
  --color-primary-950: '#062032'

  --color-secondary: '#53C165',
  --color-secondary-50: '#F8FCF9',
  --color-secondary-100: '#E9F7EB',
  --color-secondary-200: '#CBECD0',
  --color-secondary-300: '#ADE1B6',
  --color-secondary-400: '#8FD79B',
  --color-secondary-500: '#71CC80',
  --color-secondary-600: '#53C165',
  --color-secondary-700: '#3AA24B',
  --color-secondary-800: '#2B7838',
  --color-secondary-900: '#1D4F25',
  --color-secondary-950: '#153B1B'
}

body {
  margin: 0;
  font-family: var(--font-family);
  /* font-family: "Outfit", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;

@layer base {
  h1 {
    @apply text-lg sm:text-2xl md:text-3xl font-medium text-gray-950 dark:text-gray-50;
  }
  h2 {
    @apply text-2xl md:text-3xl font-bold;
  }
  h3 {
    @apply text-xl md:text-2xl font-bold;
  }
  h4 {
    @apply text-lg md:text-xl font-medium;
  }
  h5 {
    @apply text-lg font-bold;
  }
  h6 {
    @apply text-base font-bold;
  }

  /* .react-grid-item {
    transition: none !important;
  } */
}
@tailwind components;
@tailwind utilities;

@layer utilities {

  @keyframes pulseSlow {
    50% {
      opacity: .5;
    }
  }
  .animate-pulse-slow {
    animation: pulseSlow 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }


  .standard-table {
    @apply rounded-md overflow-clip table w-full;
  }

  .compact-table > tbody > tr > td {
    @apply text-sm;
  }

  .standard-table > tbody > tr > td:first-child {
    width: 1%; /* Set minimum width for first column */
    white-space: nowrap; /* Prevent wrapping */
  }
  /* .standard-table > tbody > tr > td:not(:first-child) {
          width: 99%;
      } */
  .standard-table > thead > tr > th:not(:last-child),
  .standard-table > tbody > tr > td:not(:last-child) {
    @apply border-r-2 border-gray-100;
  }
  /* Border on all rows except last row */
  .standard-table > thead > tr:last-child > th {
    @apply border-b-2 border-gray-100;
  }

  .standard-table > tbody > tr:not(:last-child) > td {
    @apply border-b-2 border-gray-100;
  }
  /* Remove bottom border from the last row */
  .standard-table > tbody > tr:last-child > td {
    @apply border-b-0;
  }
  /* Remove right border from the last column */
  .standard-table > tbody > tr > td:last-child {
    @apply border-r-0;
  }
}

body.dragging * {
  user-select: none;
}