.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.app {
  display: grid;
  
  
  /* height: 100svh; */
  position: relative;
  @apply min-h-svh md:h-screen overflow-y-auto;
}

/*For the login and register forms */
.app.fullscreen {
  grid-template-columns: 1fr; 
}

.sidebar {
  z-index: 100;
  transition: width 0.25s ease;
  /* overflow: hidden; */
}

html {
  font-size: var(--user-font-size, 16px); 
  @apply bg-gray-50 dark:bg-gray-1000 max-h-screen overflow-hidden;
}

/* body {
  @apply overflow-hidden;
} */

.open {
  width: 240px;
}

.closed {
  width: 64px;
}

@media (max-width: 768px) {
  .sidebar {
    grid-column-start: 1;
    grid-column-end: -1;
    grid-row-start: 1;
    grid-row-end: -1;
    width: 240px;
    transition: transform 0.25s  ease, width 0.25s 0.25s ease;
  }
  .sidebar.mobileClosed {
    transform: translateX(-100%); 
    /* width: 240px; */
  }
  .sidebar.closed {
    width: 240px;
  }
  .main-content {
    grid-column-start: 1;
    grid-column-end: -1;
    grid-row-start: 1;
    grid-row-end: -1;
    @apply max-h-screen;
  }
  .app {
    grid-template-columns: 1fr;
  }
  .sidebar.mobileOpen {
    transform: translateX(0);
    /* position: fixed ;  */
    /* top: 0; */
    /* width: 240px; */

  }
}

.react-grid-item:has(.dashboard-widget.active) {
  @apply z-10;
}

.dashboard-widget {
  @apply text-gray-1000 dark:text-white bg-white dark:bg-gray-950 border border-gray-400/10 dark:border-gray-500/10 rounded-xl;
}

.react-grid-item.react-grid-placeholder {
  @apply !bg-green-400/80 rounded-md pointer-events-none;
}

.react-resizable-handle {
  background-image: none;
  padding: 0;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s,
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne,
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw,
.react-grid-item > .react-resizable-handle.react-resizable-handle-se,
.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  transform: none;
  margin: 0;
}
.react-grid-item > .react-resizable-handle::after {
  border: none;
  content: none !important;
}

:root {
  --handle-color-light: #f0f0f0; /* Light mode handle color */
  --handle-color-dark: #ffffff; /* Dark mode handle color */
}

.react-resizable-handle {
  background: transparent;
  @apply dark:invert;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  width='0.6em' height='0.6em' viewBox='0 0 15 15'%3E%3Cpath fill='currentColor' fill-rule='evenodd' d='M5.123 12H3.5a.5.5 0 0 1 0-1h1.6c1.128 0 1.945 0 2.586-.053c.637-.052 1.057-.152 1.403-.328a3.5 3.5 0 0 0 1.53-1.53c.176-.346.276-.766.328-1.403C11 7.045 11 6.228 11 5.1V3.5a.5.5 0 0 1 1 0v1.623c0 1.1 0 1.958-.056 2.645c-.057.698-.175 1.265-.435 1.775a4.5 4.5 0 0 1-1.966 1.967c-.51.26-1.077.377-1.775.434C7.08 12 6.224 12 5.123 12' clip-rule='evenodd'/%3E%3C/svg%3E") !important;
}


.mapboxgl-popup-anchor-bottom {
  translate: 0 -20px;
}
.mapboxgl-popup-close-button {
  right: 5px !important
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the vertical scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: rgba(0,0,0,0); 
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    height: 25px;
    @apply hover:bg-gray-300 hover:opacity-100 duration-150 transition-opacity opacity-50 dark:hover:bg-gray-700 rounded-full bg-gray-200 dark:bg-gray-800;

  }
  
  /* .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */

  .scroll-from-bottom {
    scroll-behavior: smooth;
    scroll-margin-top: auto;
  }