  .custom-scrollbar::-webkit-scrollbar {
  width: 5px; /* Width of the vertical scrollbar */
  height: 5px; /* Height of the horizontal scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: rgba(0,0,0,0); 
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    height: 25px;
    opacity: 1;
    background: #888;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scroll-from-bottom {
    scroll-behavior: smooth;
    scroll-margin-top: auto;
  }